import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";

NProgress.configure({ 
    trickle: false,
    trickleRate: 0.02, 
    trickleSpeed: 10,
});
NProgress.inc();

function RouteProgress() {
    const { pathname } = useLocation();

    useEffect(() => {
        NProgress.start();

        setTimeout(() => {
            NProgress.done();
        }, 200);
    }, [pathname]);

    return null;
}

export default RouteProgress;
