export default function Error404() {
  return (
    <div className="flex items-center min-h-[calc(100vh-88px)] justify-center flex-col">
      <div className="text-6xl text-center font-bold">404</div>
      <div className="text-2xl text-center mb-3">
        Ooops, page not found
      </div>
      <div className="text-xl text-gray text-center px-2">
        We&apos;re sorry, we couldn&apos;t find the page you requested.
      </div>
    </div>
  )
}