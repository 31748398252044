import React, { useState, useEffect, useRef } from "react";
import { Fade, Bounce } from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";
import { useNavigate ,useLocation, Link } from 'react-router-dom';

const items = [
  {
    title: "Where expertise <br class='md:block hidden'/> meets creativity",
    subtitle: "Spaces where beauty and modernity meet",
    buttonText: "View JLR’s Spaces",
    link: "/jovy-rabelas",
    image: "/image/banner/his-works-bg4.jpg",
  },
];

const WorksBanner = ({slide}) => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="relative" id="mainContentStart">
      <div className="carousel">
        <div className="__carousel-inner">
          {items.map((e, i) => {
            return (
              <div
                className={`__carousel-item ${
                  activeIndex === i ? "active" : ""
                }`}
                key={e.title}
              >
                <Fade>
                  <div className="group relative">
                    <div className="">
                      {!Array.isArray(e.image) && <img src={e.image} className="object-cover object-bottom w-full min-h-[100vh]" alt="" />}
                    </div>
                    <div
                      className={`__carousel-content flex absolute border top-0 left-0 w-full h-full items-center justify-center flex-col show`}
                    >
                      <div className="md:w-10/12 w-4/5 md:mb-16 mb-4 overflow-hidden">
                        <h2 className="fade-left title text-white md:text-7xl text-3xl leading-[1.15!important] font-black mb-10 mt-5">
                          { ReactHtmlParser(e.title) }
                        </h2>
                        <div className="text-white mb-2">{e.subtitle}</div>
                        <a href="/jlr-spaces" className='flex items-center mb-16'>
                          <span className="text-xl font-bold underline text-white">View JLR’s Spaces</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default WorksBanner;
