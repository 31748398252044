import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import { Bars } from 'react-loader-spinner';
import { Fade } from 'react-awesome-reveal';
import NotifModal from  './NotifModal';
import HubspotContactForm from '../forms/HubSpotForms/HubSpotContactForm';

const customStyles = {
  content: {
    border: '0'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.85)',
    zIndex: 1050,
  }
}

Modal.setAppElement('#root');

// Check whether the popup already show
// if show display the popup
// upon submit make sure that user identity is stored so that we can verify the record if user refresh the page


export default function SubscriptionModal3 ({show, close}) {

  const [loading, setLoading] = useState(false);
  const [subscribe, setSubscribe] = useState();
  const [notif, setNotif] = useState(false);
  const [submitted, setSubmitted] = useState(false)

  const handleClose = () => {
    setSubmitted(false)
    close()
  }

  return (
    <>
      <Modal 
        isOpen={show}
        style={customStyles}
        className="flex justify-center w-full h-full border-none outline-none"
      >
        <div className="md:w-8/12 w-full md:h-[90%] relative md:rounded-[1em] fixed top-1/2 translate-y-[-50%] transition duration-500" style={{background: '#7B6C56 url(img/dialog/background.jpg) center/cover no-repeat'}}>
          <Fade className={`overflow-auto w-full h-full md:px-10 sm:px-5 px-3 block mx-auto ${submitted ? 'sm:pt-[5%] pt-[28%]' : 'sm:pt-[2%] pt-[4%]'}`}>
            <div className="relative w-full sm:h-full">
              <div className={`md:border-[1rem] border-[5px] border-[#0b0f19b5] rounded-t-3xl ${submitted ? 'lg:h-[75%] sm:h-[70%] h-[100%]' : 'xl:h-[120%] lg:h-[70%] md:h-[60%] sm:h-[50%] h-[90%]'} bg-[#D8D7D6] relative`}>
                <div className="absolute right-3 top-3">
                  <button className="text-4xl text-[#C2082A] z-20" onClick={handleClose}>
                    <AiFillCloseCircle />
                  </button>
                </div>
                <div className='h-full overflow-auto'>
                  {
                    loading && (
                      <div className="h-full w-full absolute left-0 z-10 top-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center rounded-lg flex-col">
                        <Bars color="#CA0025" height={65} width={65} />
                      </div>
                    )
                  }
                  {
                    !subscribe && (
                      <div className="grid grid-cols-1 gap-7 md:p-10 p-5 h-full">
                      <div className="flex flex-col justify-center items-center">
                        <div className="logoWrapper mb-3">
                          <img src="img/top-section/T360-Logo-plain.png" className="max-w-[150px]"  alt="tagline 360 logo"/>
                        </div>
                        {
                          !submitted &&
                          <div className='text-center md:w-8/12 mx-auto leading-5'>
                            <div className="font-bold mb-2 text-[1.75rem]">
                              Talk to us today!
                            </div>
                            <div className="font-inherit mb-5">
                              Fill in the details below for your inquiry and a customer representative will be in touch with you right away
                            </div>
                          </div>
                        }
                        <div className='w-full my-5'>

                          <HubspotContactForm 
                            region="na1"
                            portalId="21865908"
                            formId="29af89f8-2a00-4edc-bf22-d7b43505ea70"
                            setSubmitted={setSubmitted}
                          />
                        </div>
                      </div>
                    </div>
                    )
                  }
                </div>
              </div>
              <div className="bg-[#868382d9] rounded-b-3xl sm:h-[10%]"> </div>
              <div className="sm:hidden block absolute sm:bottom-[4%] bottom-[1%] left-1/2 w-[2.25em] h-[2.25em] bg-lightgray rounded-full translate-x-[-50%]"> </div>
              <div className="sm:w-[20%] h-[5%] sm:rounded-b-none rounded-b-xl block mx-auto border-b-[50px] border-b-[#615d59f0] sm:border-l-[0.5em] sm:border-l-[transparent]  sm:border-r-[0.5em] border-r-[transparent]"></div>
            </div>
          </Fade>
        </div>
      </Modal>
      
      <NotifModal show={notif} dismiss={() => setNotif(false)} />
    </>
  );
}
