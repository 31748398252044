import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { spaces } from 'src/common/spaces';
import NotFound from 'src/pages/404'


const handleSlideChange = () => {
  // alert(1)
}
function SpacesInner() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [singleData, setSingleData] = useState(null);
  const location = useLocation()

  useState(() => {
    const pathname = location.pathname
    const regex = /\/([^/]+)$/
    const slug = pathname.match(regex)
    const cleanSlug = slug[1];

    const data = spaces.filter(space => space.slug === cleanSlug)
    setSingleData(...data)
  }, [])

  if (singleData) console.log(singleData)

  if (singleData)
    return (
      <div id="spacesInner" className="site-page max-w-[1920px] pt-[72px] block mx-auto">
        <div className="w-full h-[calc(100vh-152px)] p-5 overflow-scroll">
          <div className="md:max-w-[992px] mx-auto mx-auto">
            <div className="flex md:flex-col flex-col-reverse">
              <div className="mb-5 relative">
                <Carousel
                  showArrows={true}
                  showStatus={true}
                  showThumbs={true}
                  stopOnHover={true}
                  autoPlay={false}
                  infiniteLoop={false}
                  interval={2000}
                  emulateTouch={true}
                  showIndicators={false}
                  className=''

                  onChange={handleSlideChange}
                >

                  {
                    singleData.gallery.map((item, i) => (
                      <div>
                        <img src={item} alt='' />
                      </div>
                    ))
                  }
                </Carousel>
              </div>
              
              <div>
                <div className="md:mb-0 mb-5">
                  <div className="text-2xl font-bold">{singleData.name}</div>
                  <div className="text-md mb-5">Project Type: {singleData.type}</div>
                  <div className="text-sm">{singleData.description}</div>
                </div>
                <div className="mt-5">
                  <Link to="/jlr-spaces" className='flex items-center md:mb-16 mb-10'>
                    <span className="text-md font-bold underline">Back To JLR’s Spaces</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  else
    return <NotFound />
}

export default SpacesInner;

