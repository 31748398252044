const spaces = [  
  {
    name: 'The Happy Place',
    type: 'Hotel',
    description: 'Happiness is the foundation of this 4-star hotel. Classic elements fused with vivid hues evoke joy, resulting in a space splashed with a play of colors. Balancing serenity with excitement, guests are sure to find happiness in this home away from home.',
    img: '/image/spaces/happy-place.jpg',
    alt: '',
    slug: 'the-happy-place',
    gallery: [
      '/image/spaces/the-happy-place/1.jpg',
      '/image/spaces/the-happy-place/2.jpg',
      '/image/spaces/the-happy-place/3.jpg',
      '/image/spaces/the-happy-place/4.jpg',
      '/image/spaces/the-happy-place/5.jpg',
      '/image/spaces/the-happy-place/6.jpg',
    ]
  },
  {
    name: 'Modern Pop in Color',
    type: 'Office',
    description: 'Vibrant and stylish, this office boasts of a chic lounge with brightly colored open work spaces complemented with glass and neutral tones. Bold pieces accentuate the space, inspiring creativity and productivity.',
    img: '/image/spaces/modern-pop-in-color/1.jpg',
    alt: '',
    slug: 'modern-pop-in-color',
    gallery: [
      '/image/spaces/modern-pop-in-color/2.jpg',
      '/image/spaces/modern-pop-in-color/3.jpg',
      '/image/spaces/modern-pop-in-color/4.jpg',
      '/image/spaces/modern-pop-in-color/5.jpg',
    ]
  },
  {
    name: 'The Modern Bold and Beautiful',
    type: 'Beauty Salon',
    description: 'Fusion and versatility are at the core of this salon design; a balance of concepts that are inviting to all and intimidating to none. Neither too masculine nor too feminine, the space veers away from the typical aesthetics of a salon that can often be polarizing. By fusing the right mix of sleek and elegant materials, forms and details, the salon caters to the modern person, regardless of their gender—all while retaining an air of expertise and beauty that gives any customer the confidence to get that trendy do.',
    img: '/image/spaces/modern.jpg',
    alt: '',
    slug: 'the-modern-bold-and-beautiful',
    gallery: [
      '/image/spaces/the-modern-bold-and-beautiful/1.jpg',
      '/image/spaces/the-modern-bold-and-beautiful/2.jpg',
      '/image/spaces/the-modern-bold-and-beautiful/3.jpg',
      '/image/spaces/the-modern-bold-and-beautiful/4.jpg',
      '/image/spaces/the-modern-bold-and-beautiful/5.jpg',
      '/image/spaces/the-modern-bold-and-beautiful/6.jpg',
    ]
  },
  {
    name: 'New York Subway Styled',
    type: 'Office',
    description: 'This layout takes your work “station” to a whole new level. Decked out with industrial beams, exposed material, and subway motifs, this office transports you to your most productive state.',
    img: '/image/spaces/new-york-subway-2.jpg',
    alt: '',
    slug: 'new-york-subway',
    gallery: [
      '/image/spaces/new-york-subway/1.jpg',
      '/image/spaces/new-york-subway/2.jpg',
      '/image/spaces/new-york-subway/3.jpg',
      '/image/spaces/new-york-subway/4.jpg',
      '/image/spaces/new-york-subway/5.jpg',
      '/image/spaces/new-york-subway/6.jpg',
      '/image/spaces/new-york-subway/7.jpg',
      '/image/spaces/new-york-subway/8.jpg',
      // '/image/spaces/new-york-subway/9.jpg',
      // '/image/spaces/new-york-subway/10.jpg',
      '/image/spaces/new-york-subway/11.jpg',
    ]
  },
  {
    name: 'Family Mansion',
    type: 'Residential',
    description: 'The quintessential retirement home for a New Yorker. This space showcases a wide driveway that gives access to family-oriented areas such as gazebos, lounges, and outdoor areas. While it evokes a classic Mediterranean look in its forms and color choices, the home is more designed with the family in mind; encouraging get-togethers and camaraderie—impeccably balancing quality time, a healthy amount of nature, and aesthetic beauty.',
    img: '/image/spaces/family-mansion.jpg',
    alt: '',
    slug: 'family-mansion',
    gallery: [
      '/image/spaces/family-mansion/1.jpg',
      '/image/spaces/family-mansion/2.jpg',
      '/image/spaces/family-mansion/3.jpg',
      '/image/spaces/family-mansion/4.jpg',
      '/image/spaces/family-mansion/5.jpg',
      '/image/spaces/family-mansion/6.jpg',
    ]
  },
  {
    name: 'An Industrial Abode',
    type: 'Condominium',
    description: 'A fresh take on rustic and industrial motifs with bold hues and accents – creating a warm and cozy atmosphere; a calming nest for busy urban dwellers.',
    img: '/image/spaces/industrial.jpg',
    alt: '',
    slug: 'an-industrial-abode',
    gallery: [
      '/image/spaces/an-industrial-abode/1.png',
      '/image/spaces/an-industrial-abode/2.png',
      '/image/spaces/an-industrial-abode/3.png',
      '/image/spaces/an-industrial-abode/4.png',
      '/image/spaces/an-industrial-abode/5.png',
      '/image/spaces/an-industrial-abode/6.png',
    ]
  },
  {
    name: 'Spruced Up Spaces',
    type: 'Condominium',
    description: 'The Havana-inspired design brings the vibrant island colors to the condominium space, a haven for the eclectic of tastes. Striking elements of tropical flora serves as the primary visual, complemented by Scandinavian furniture for a modern, refreshed look.',
    img: '/image/spaces/spruced.jpg',
    alt: '',
    slug: 'spruced-up-spaces',
    gallery: [
      '/image/spaces/spruced-up-spaces/1.png',
      '/image/spaces/spruced-up-spaces/2.png',
      '/image/spaces/spruced-up-spaces/3.png',
      '/image/spaces/spruced-up-spaces/4.png',
      '/image/spaces/spruced-up-spaces/5.png',
      '/image/spaces/spruced-up-spaces/6.png',
    ]
  },
  {
    name: 'Modern Industrial Open Kitchen and Dining',
    type: 'Condominium',
    description: 'Inspired by Gordon Ramsay, this 1-bedroom condominium unit was transformed into a spacious kitchen and dining haven with a modern-rustic fusion – highlighted by a seamless fusion of neutral tones, wood furnitures with chrome finish, decals, polished stones, and with a set of aberrant drop light taking the center spot, a fitting spotlight of the space.',
    img: '/image/spaces/modern-industrial-open-kitchen-and-dining/1.jpg',
    alt: '',
    slug: 'modern-industrial-open-kitchen-and-dining',
    gallery: [
      '/image/spaces/modern-industrial-open-kitchen-and-dining/3.jpg',
      '/image/spaces/modern-industrial-open-kitchen-and-dining/4.jpg',
      '/image/spaces/modern-industrial-open-kitchen-and-dining/5.jpg',
    ]
  },
  {
    name: 'Unity in Diversity',
    type: 'Office',
    description: 'Exuding warmth with a welcoming distinct Filipino touch, this office embraces original local creations. The open layout and warm colors evoke hospitality, making the space feel expansive despite its compact design.',
    img: '/image/spaces/unity-in-diversity/1.jpg',
    alt: '',
    slug: 'unity-in-diversity',
    gallery: [
      '/image/spaces/unity-in-diversity/2.jpg',
      '/image/spaces/unity-in-diversity/3.jpg',
      '/image/spaces/unity-in-diversity/4.jpg',
      '/image/spaces/unity-in-diversity/5.jpg',
      '/image/spaces/unity-in-diversity/6.jpg',
      '/image/spaces/unity-in-diversity/7.jpg',
    ]
  },
  // {
  //   name: 'Elegantly Breezy',
  //   type: 'Residential',
  //   description: 'Welcoming and stylish, the foyer sets the tone for the home’s cool theme – a contemporary breezy, elegant marina that seamlessly blends natural and sophisticated elements. The white finishes brought a modern and organic atmosphere, with antique and fresh finishes making adds some warm and cozy, a perfect balance to the airy comfort the whole experience radiates.',
  //   img: '/image/spaces/elegantly-breezy/1.jpg',
  //   alt: '',
  //   slug: 'elegantly-breezy',
  //   gallery: [
  //     '/image/spaces/elegantly-breezy/2.jpg',
  //     '/image/spaces/elegantly-breezy/3.jpg',
  //     '/image/spaces/elegantly-breezy/4.jpg',
  //     '/image/spaces/elegantly-breezy/5.jpg',
  //     '/image/spaces/elegantly-breezy/6.jpg',
  //   ]
  // },
]

export { spaces }