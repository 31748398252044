import React, {useState, useEffect} from 'react';
import Banner from 'src/components/banner/WorksBanner';

function Works() {
  return (
    <div className="site-page max-w-[1920px] block mx-auto">
      <Banner />
    </div>
  
  );
}

export default Works;

