import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a load
import styled from 'styled-components'
import { Fade, Bounce } from 'react-reveal';
import ReactHtmlParser from "react-html-parser";

const ImageWrapper = styled.div`
  border-radius: 1em;
  overflow: hidden;
  box-shadow: 0 0 2px rgba(0,0,0,0.25);
  @media (max-width: 992px) {
    border-radius: 0;
  }
`;
const Image = styled.img`
  height: 100%;
  max-height: 675px;
  object-fit: cover;
  @media (max-width: 992px) {
    max-height: 746px;
  }
`;

const data = [
  {
    title: 'Wander in the Wild',
    caption: 'Find the fleeting beauty of flora and fauna',
    banner: '1.jpg',
    gallery: [
      { src: '2.jpg', alt: '' },
      { src: '3.jpg', alt: '' },
      { src: '4.jpg', alt: '' }
    ]
  },
  {
    title: 'Captivating Cultures',
    caption: 'Get to know the faces that make the places',
    banner: '1.jpg',
    gallery: [
      { src: '2.jpg', alt: '' },
      { src: '3.jpg', alt: '' },
      { src: '4.jpg', alt: '' }
    ]
  },
  {
    title: 'The Arts that Elevate Life',
    caption: 'Immerse in art, inspire the heart',
    banner: '1.jpg',
    gallery: [
      { src: '2.jpg', alt: '' },
      { src: '3.jpg', alt: '' },
      { src: '4.jpg', alt: '' }
    ]
  },
  {
    title: 'Urban Sophistication',
    caption: 'Cosmopolitan pleasures from <br class="md:block hidden"/>the world’s fashion capitals',
    banner: '1.jpg',
    gallery: [
      { src: '2.jpg', alt: '' },
      { src: '3.jpg', alt: '' },
      { src: '4.jpg', alt: '' }
    ]
  },
]

function Travel() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    // Your logic for handling resize
    console.log('Window resized!');
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSlideChange = () => {

  }

  useEffect(() => {
    const carouselDots = document.querySelector('.control-dots')
    const carouselPrev = document.querySelector('.control-prev')
    const carouselNext = document.querySelector('.control-next')
    const carouselIndicator = document.querySelector('.carousel-indicator')
    const carouselIndicatorNav = document.querySelector('.carousel-indicator-nav')

    const toAppend = [carouselDots, carouselPrev, carouselNext]

    if (carouselDots && carouselPrev && carouselIndicator) {
      carouselIndicator.appendChild(carouselDots);

      if (carouselIndicatorNav && carouselPrev && carouselNext) {
        carouselIndicatorNav.appendChild(carouselPrev);
        carouselIndicatorNav.appendChild(carouselNext);
      }
    }

  }, [])

  return (
    <div id="travel" className="max-w-[1920px] block mx-auto pt-[72px] relative">
      <Carousel
        showArrows={true}
        showStatus={false}
        showThumbs={false}
        stopOnHover={true}
        autoPlay={false}
        infiniteLoop={false}
        interval={2000}
        emulateTouch={false}
        showIndicators={true}
        swipeable={false}
        className=''
        onChange={handleSlideChange}

      >

        {
          data.map((slide, i) => (
            <div className='grid grid-cols-12' key={i}>
              <div className="lg:col-span-6 col-span-12">
                <img src={`/image/travel/slide${i + 1}/${slide.banner}`} className='lg:h-full sm:min-h-[calc(100vh-152px)] h-[530px] object-cover travel-banner' />
              </div>
              <div className="lg:col-span-6 col-span-12 content-wrapper text-left">
                <div className="relative h-full lg:p-10 p-portrait-remove">
                  {/* <div className='content lg:mb-14 lg:mt-8 lg:p-0 lg:ml-16 md:10 p-5 lg:min-h-[auto] min-h-[145px] flex items-start md:justify-center flex-col'> */}
                  <div className='content lg:mb-14 lg:mt-8 lg:p-0 lg:ml-16 md:10 p-5 xl:min-h-[auto] min-h-[145px] flex items-start md:justify-center flex-col'>
                    <Fade>
                      <div className="md:text-4xl text-xl font-bold lg:mb-4">{slide.title}</div>
                      <div className="md:text-xl text-sm">{ReactHtmlParser(slide.caption)}</div>
                    </Fade>
                  </div>
                  <div className="relative">
                    <div className="travel-gallery grid lg:grid-cols-3 grid-cols-1 lg:gap-10 relative lg:-ml-[25%]">
                      {
                        slide.gallery.map((img, j) => (
                          <div className="col-span-1" key={j}>
                            <ImageWrapper>
                              <Image src={`/image/travel/slide${i + 1}/${img.src}`} alt="" />
                            </ImageWrapper>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          )
        }
      </Carousel>
      <div className="carousel-indicator lg:px-10 px-4 py-5 lg:-mt-[75px]">
        <div className="carousel-indicator-nav"></div>
      </div>
    </div>
  );
}

export default Travel;

