import React, {useEffect, useState} from "react";
import { Bars } from 'react-loader-spinner';
    
const HubspotContactForm = props => {
    const { region, portalId, formId, setSubmitted } = props;

  const [loading, setLoading] = useState(true);

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

    
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore

                setTimeout(() => {
                    // remove loader
                    setLoading(false)
                }, 0);

                window.hbspt.forms.create({
                    region: region,
                    portalId: portalId,
                    formId: formId,
                    target: '#contactForm',
                    onFormSubmit: function() {
                        setSubmitted(true)
                    } 
                })
            }
        });
    }, [region, portalId, formId, setSubmitted]);

    return (
        <div className="relative">
            {
                loading && (
                    <div className="h-full w-full absolute left-0 z-10 top-0 bg-[rgba(255,255,255,0)] flex items-center justify-center rounded-lg flex-col">
                    <Bars color="#CA0025" height={65} width={65} />
                    </div>
                )
            }
            <div id="contactForm"></div>
        </div>
    );
};

export default HubspotContactForm;