import React, { useState, useEffect } from 'react';
import Vimeo from "@u-wave/react-vimeo";
import { FaRegEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { useNavigate, Link } from 'react-router-dom';

// ** Modal Imports
import HubspotContactForm from 'src/components/forms/HubSpotForms/HubSpotContactForm';

function About() {
  // ** States
  const [showFormModal, setFormModal] = useState(false)
  const [region, setRegion] = useState("")
  const [portalId, setPortalId] = useState("")
  const [formId, setFormId] = useState("")
  const [slug, setSlug] = useState("")

  const navigate = useNavigate();

  const handleModal = (region, portalId, formId) => {
    setRegion(region)
    setPortalId(portalId)
    setFormId(formId)
    setFormModal(true)
  }

  const handleClose = () => {
    setFormModal(false)
  }
  const handleNavigateBack = () => {
    navigate(-1)
  }

  return (
    <div id="about" className="site-page max-w-[1920px] mx-auto overflow-x-hidden">
      <div className="container relative mx-auto mt-[72px] h-[calc(100vh-152px)]">
        <div className="w-full h-full absolute inset-0 -z[1]">
          <div className="grid grid-cols-12 h-full xl:px-0 px-5">
            <div className="lg:col-span-8 col-span-12 flex flex-col h-[530px]">
              <button onClick={handleNavigateBack} className='flex items-center mt-10 w-fit'>
                <svg width="10" height="26" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2678 0.761522C15.2441 1.77689 15.2441 3.42311 14.2678 4.43848L6.03553 13L14.2678 21.5615C15.2441 22.5769 15.2441 24.2231 14.2678 25.2385C13.2915 26.2538 11.7085 26.2538 10.7322 25.2385L0.732233 14.8385C-0.244078 13.8231 -0.244078 12.1769 0.732233 11.1615L10.7322 0.761522C11.7085 -0.253841 13.2915 -0.253841 14.2678 0.761522Z" fill="black" />
                </svg>
                <div className="sm:text-xl text-lg font-bold ml-3">BACK</div>
              </button>
              <div className='flex flex-col lg:justify-center justify-end h-full'>
                <div className="sm:text-3xl text-lg text-[#28B0A6] font-bold sm:mb-5 mb-2 md:mt-0 mt-5">About</div>
                <div className="sm:text-3xl text-xl font-medium mb-5">THE JOVY RABELAS EMBLEM<br className='md:block hidden' /> OF EXCELLENCE</div>
                <div className='sm:text-md text-sm text-[#99A3AD] sm:mb-10 mb-5'>
                  <p className='mb-5'>
                    Jovy Rabelas is a visionary interior decorator certified by the British Academy
                    of Interior Design. <br className='xl:block hidden' />Based in New York and a globetrotter by nature, his creative eye is calibrated for the latest trends around the world. Drawing inspiration from his insatiable wanderlust and passion for all things beautiful.
                  </p>
                  <p>
                    Jovy is also a seasoned brand architect, and experience engineer - a standout curator of narratives that spark conversation, and inspire meaningful connections.
                  </p>

                </div>
                <Link to="/jlr-spaces" className='flex items-center block w-fit'>
                  <span className="sm:text-xl text-md font-bold underline">View JLR’s Spaces</span>
                </Link>
                <div className="flex gap-4 sm:mt-10 mt-5">
                  <a href="https://tagline.com.ph/" target='_blank'>
                    <img src="/image/tagline-group-logo-2.png" className='sm:w-[105px] w-[75px]' />
                  </a>
                  <a href="https://tagline360.com/" target='_blank'>
                    <img src="/image/tagline360-logo-2.png" className='sm:w-[105px] w-[75px]' />
                  </a>
                </div>
              </div>
            </div>
            <div className="lg:col-span-4 col-span-12 lg:pt-0 pt-10 h-full relative">
              {/* <img src="/image/jlr-photo3.png" className='ml-auto max-w-[104.5%] md:absolute bottom-0 -right-[20%]' alt="Jovy Rabelas Profile" /> */}
              {/* <div className='md:w-[445px] h-full border'> */}
              {/* <div className='jlr-profile h-full'> */}
              <div className='jlr-profile h-full ml-auto'>
                <img
                  src="/image/jlr-photo7.png"
                  srcSet=''
                  className='ml-auto object-bottom bottom-0 w-full h-full'
                  alt="Jovy Rabelas Profile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default About;

