import React, { useState, useEffect } from 'react';
import Layout from "src/pages/Layout"
import Home from "src/pages/Home"
import About from "src/pages/About"
import Works from "src/pages/Works"
import Spaces from "src/pages/Spaces"
import Travel from "src/pages/Travel"
import Foods from "src/pages/Foods"
import SpacesInner from "src/pages/SpacesInner"
// import Food from "src/pages/Food"
import Error404 from "src/pages/404"
import RouteProgress from 'src/components/routes/RouteProgress';


import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {

    return (
        <>
            <BrowserRouter>
                <RouteProgress />
                <Routes>
                    <Route path="/" element={<Layout />} >
                        <Route index element={<Home />} />
                        <Route path="/about" element={<Home />} />
                        <Route path="/spaces" element={<Home />} />
                        <Route path="/travel" element={<Home />} />
                        <Route path="/food" element={<Home />} />
                        <Route path="/contact" element={<Home />} />
                        <Route path="/jlr-travels" element={<Travel />} />
                        <Route path="/jovy-rabelas" element={<About />} />
                        <Route path="/his-works" element={<Works />} />
                        <Route path="/jlr-spaces" element={<Spaces />} />
                        <Route path="/jlr-spaces/:space" element={<SpacesInner />} />
                        <Route path="/jlr-foods" element={<Foods />} />
                        {/* <Route path="/project" element={<Project />} /> */}
                        <Route path="*" element={<Error404 />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}