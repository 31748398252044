import React, {useState, useEffect} from 'react';
import Banner from 'src/components/banner/LandingCarousel';

function Landing() {
  return (
    <div className="site-page max-w-[1920px] block mx-auto">
      <Banner />
    </div>
  );
}

export default Landing;

