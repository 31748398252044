import { useEffect, useState } from "react"
import ReactDOM from 'react-dom/client'
import { Link } from 'react-router-dom'
import { PrimaryMenu } from '../menus';
import style from 'src/css/_header.module.scss'
import Landing from 'src/pages/Home';
import { Fade } from "react-reveal";

const isValidUrl = url => /^[a-z][a-z0-9+.-]*:/.test(url);

const listItems = PrimaryMenu.map((items) => {
    return (
        <li className={style.menuItem} key={items.label}>
            <Link to={items.path} aria-labelledby={`Open ${items.label} page`} target={isValidUrl(items.path) ? '_blank' : '_self'}> {items.label} </Link>
        </li>
    )
});

const mobileListItems = PrimaryMenu.map((items) => {
    return (
        <li className="border-b border-gray-400 my-5" key={items.label}>
            <a className="text-white" href={items.path} aria-labelledby={`Open ${items.label} page`}>{items.label}</a>
        </li>
    )
});

const Header = props => {
    // ** Props
    const { handleBlur, handleFocus, location } = props
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleAside = () => {
        const aside = document.getElementById('aside');
        aside.classList.toggle('active');

        setIsNavOpen(false)
    }

    useEffect(() => {

    }, [isNavOpen])

    return (
        <header className="absolute py-5 z-50 w-full h-[72px] max-w-[1920px] left-1/2 -translate-x-2/4" style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
            <div className="__header-wrapper flex justify-between sm:px-16 items-center">
                <div className="brand sm:px-0 px-[1rem] flex items-center">
                    <Link to="/" aria-label="Home">
                        <img src="/image/JL-RABELAS-logo.svg" width={150} alt="" className="max-w-full" />
                    </Link>
                </div>
                <nav className="__navigation lg:w-[68%] lg:block flex items-center gap-2">
                    {/* desktop nav */}
                    <div className="flex justify-between gap-6">
                        <ul className={`hidden lg:flex ${style.menu}`}>
                            {listItems}
                        </ul>
                        <button className={`${style.getInTouchBtn} flex items-center gap-4 hidden lg:flex`} onClick={toggleAside}>
                            Get In Touch
                            <img src="/image/right_arrow.svg" width={20} alt="" className="max-w-full" />
                        </button>
                    </div>
                    {/* mobile nav */}
                    <section className="flex lg:hidden sm:mx-0 mx-5">
                        {/* hamburger menu */}
                        <div
                            className="space-y-2"
                            onClick={() => setIsNavOpen((prev) => !prev)}
                        >
                            <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
                            <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
                            <span className="block h-0.5 w-8 animate-pulse bg-[#ffffff]"></span>
                        </div>

                        <div className={isNavOpen ? "showMenuNav transition transition-opacity ease-in-out delay-150" : "hideMenuNav"}>
                            <Fade duration={500} >
                                {/* <div className=" shrink-0 z-20">
                                    <img src="/img/hubspot-partner-logo.png" width={90} alt="" className="max-w-full" />
                                </div> */}
                                {/* cross icon */}
                                <div
                                    className="absolute top-0 right-0 px-8 py-8"
                                    onClick={() => setIsNavOpen((prev) => !prev)}
                                >
                                    <svg
                                        className="h-8 w-8 text-gray-600"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="18" y1="6" x2="6" y2="18" />
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </div>
                                <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
                                    {mobileListItems}
                                </ul>
                                <button className={`text-white flex items-center gap-4 flex lg:hidden mt-4`} onClick={toggleAside}>
                                    Get In Touch
                                </button>
                            </Fade>
                        </div>
                    </section>
                </nav>
            </div>
        </header>
    );
}

export default Header;