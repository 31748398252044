import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components'
import axios from 'axios';

import NotifModal from '../modal/NotifModal';

const FormGroup = styled.div`
    margin-bottom: 0.5rem;
`;
const FormLabel = styled.label`
    color: #7A7A7A;
    font-size: 14px;
`;
const FormInput = styled.input`
    margin-top: 0.375rem;
    background-color: #242323;
    color: #ccc;
    border-radius: 0.25rem;
    padding: 0.5rem 0.74rem;
    width: 100%;
    font-size: 14px;
    &:focus {
        outline: none;
    }
`;
const FormTextarea = styled.textarea`
    margin-top: 0.375rem;
    background-color: #242323;
    color: #ccc;
    border-radius: 0.25rem;
    padding: 0.5rem 0.74rem;
    width: 100%;
    resize: none;
    font-size: 14px;
    &:focus {
        outline: none;
    }
`;
const FormButton = styled.button`
    width: 175px;
    padding: 0.375rem;
    border-radius: 0.25rem;
    background-color: #28B0A6;
    transition: background-color 0.25s ease-in-out;
    color: #ffffff;
    margin-bottom: 3rem;
    font-size: 14px;
    &:hover, &:focus {
        background-color: #1b817a;
    }
`;


const Aside = () => {
    const [notif, setNotif] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = (data) => {
        setIsLoading(true);


        axios.get(`${process.env.REACT_APP_DEV_BASE_URL}/sanctum/csrf-cookie`)
            .then(response => {
                // axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/subscribe`, data)
                axios.post(`${process.env.REACT_APP_DEV_BASE_URL}/api/send-inquiry`, data)
                    .then(response => {
                        console.log(response.status)
                        if (response.status) {
                            setIsLoading(false)
                            setNotif(true)
                            reset()
                            // setTimeout(() => {
                            //     toggleAside()
                            // }, 0);
                        }
                    });
            })

    }
    const closeNotif = () => {
        setNotif(false)
        toggleAside()
    }

    const toggleAside = () => {
        const aside = document.getElementById('aside');
        aside.classList.toggle('active');
    }

    return (
        // <div id="aside" className="__aside absolute right-0 top-[72px] z-20 h-[calc(100%-72px)] md:overflow-hidden overflow-auto bg-[#0B0B0B] md:w-[500px] w-full ease-in-out duration-500">
        <div id="aside" className="__aside fixed right-0 md:top-[125px] top-[72px] z-20  md:h-[calc(100vh-125px)] h-[calc(100vh-72px)] md:overflow-hidden overflow-auto bg-[#0B0B0B] md:w-[500px] w-full ease-in-out duration-500">
            <div className="flex justify-between w-full border md:p-10 p-5 md:pb-2">
                <div className="text-[1.25rem] font-semibold text-white">
                    Hello! <br />
                    Drop your message
                </div>
                <div className="hover:scale-[1.1] ease-in-out duration-300 cursor-pointer" onClick={toggleAside}>
                    <img src="/image/close_icon.svg" width={15} alt="" className="ml-auto" />
                </div>
            </div>
            <div className="md:px-10 px-5 overflow-scroll md:h-[calc(100%-125px)]">
                <div className="overflow-scroll">
                    <div className="mt-7">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup>
                                <FormLabel htmlFor="name">Full Name</FormLabel>
                                <FormInput
                                    id="name"
                                    type="text"
                                    placeholder="Enter your name"
                                    {...register('name', {
                                        required: true,
                                        pattern: /^[A-Za-z ]+$/i
                                    }
                                    )}
                                />
                                <div className="text-primary text-sm">
                                    {errors.name?.type === 'required' && (<div className="text-primary text-sm">This field is required</div>)}
                                    {errors.name?.type === 'pattern' && (<div className="text-primary text-sm">Please enter valid name</div>)}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <FormInput
                                    id="email"
                                    type="email"
                                    {...register('email', {
                                        required: 'Email address is required',
                                        maxLength: {
                                            value: 255,
                                            message: "Must be max 255 chars",
                                        },
                                        validate: (value) => {
                                            return (
                                                [/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/].every((pattern) =>
                                                    pattern.test(value)
                                                ) || "Please enter a valid email"
                                            );
                                        },
                                    })}
                                    placeholder="Enter your email"
                                />
                                <div className="text-primary text-sm">
                                    {errors.email?.message}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="email">Contact number </FormLabel>
                                <FormInput type="text"
                                    {...register('contact_no', {
                                        required: 'Contact Number is required',
                                        maxLength: {
                                            value: 13,
                                            message: "Must be max 13 chars",
                                        },
                                        validate: (value) => {
                                            return (
                                                [/^[0-9]+$/].every((pattern) =>
                                                    pattern.test(value)
                                                ) || "Please enter numbers only"
                                            );
                                        },
                                    })}
                                    maxLength="15"
                                    placeholder="Enter your contact number"
                                />
                                <div className="text-primary text-sm">
                                    {errors.contact_no?.message}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="message">Message</FormLabel>
                                <FormTextarea
                                    rows={3}
                                    id="message"
                                    placeholder="Enter your message"
                                    {...register('message', {
                                        required: true,
                                        pattern: /^[A-Za-z ]+$/i
                                    }
                                    )}
                                />
                                <div className="text-primary text-sm">
                                    {errors.message?.type === 'required' && (<div className="text-primary text-sm">Message field is required</div>)}
                                </div>
                            </FormGroup>
                            <FormButton style={{ marginTop: 10, background: isLoading ? '#6d6d6d' : '' }} disabled={isLoading}>{isLoading ? 'Processing...' : 'Send'}</FormButton>
                        </form>
                    </div>
                </div>
            </div>
            <NotifModal show={notif} dismiss={closeNotif} />
        </div>
    )
}

export default Aside;