import { useState, useEffect } from 'react'
import Header from "src/components/navigations/Header"
import Aside from "src/components/navigations/Aside"
import Footer from 'src/components/Footer';
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

import 'src/css/_landing.scss'
import RouteProgress from 'src/components/routes/RouteProgress';

// ** Modal Imports
import SubscriptionModal3 from 'src/components/modal/SubscriptionModal3'

export default function Layout({ children }) {
    const [showSubscriptionModal3, setShowSubscriptionModal3] = useState(false)
    const [slug, setSlug] = useState('')

    // ** Hooks
    const location = useLocation()

    const handleBlur = (e) => {
        const currentTarget = e.currentTarget;
        currentTarget.querySelector('div.t360-skip-links').style.visibility = "hidden"
    };

    const handleFocus = (e) => {
        const currentTarget = e.currentTarget;
        if (currentTarget === document.activeElement) {
            currentTarget.querySelector('div.t360-skip-links').style.visibility = "visible"
        }
    }

    useState(() => {

        if (location) {
            const pathname = location.pathname
            const regex = /\/([^/]+)$/
            const slugExtracted = pathname.match(regex)

            if (location.pathname === '/')
                document.querySelector('body').style.overflow = 'hidden'

            if (slugExtracted) {
                const cleanSlug = slugExtracted[1];
                const absolutes = ['about', 'spaces', 'travel', 'food'];

                if (pathname === '/' || absolutes.includes(cleanSlug)) {
                    document.querySelector('body').style.overflow = 'hidden'
                } else {
                    document.querySelector('body').style.overflow = 'initial'
                }
            }

        }
    }, [location])


    return (
        <>
            <RouteProgress />
            <Header handleFocus={handleFocus} handleBlur={handleBlur} location={location} />
            <div id="topMost"></div>
            <div className="relative">
                <Aside />
                <Outlet />
            </div>

            <Footer />
            <SubscriptionModal3 show={showSubscriptionModal3} close={() => setShowSubscriptionModal3(false)} />
        </>
    )
}