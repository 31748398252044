import React, { useState, useEffect, useRef } from "react";
import { Fade, Bounce } from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";
import { useNavigate, useLocation, Link } from 'react-router-dom';

const items = [
  {
    title: "A globetrotter <br/>with a thirst for life",
    description: '',
    buttonText: "About",
    link: "/jovy-rabelas",
    image: "/image/banner/_about-poster3.jpg",
    showNav: true,
  },
  {
    title: "An artist who loves <br/>to elevate spaces",
    description: 'Spaces where beauty and modernity meet',
    buttonText: "View JLR’s Spaces",
    link: "/jlr-spaces",
    image: "/image/banner/_his-works2.jpg",
    showNav: true,
  },
  {
    title: "Crossing borders, <br/>Curating experiences, <br/>Cultivating perspectives",
    description: 'Travel is the origin of inspiration, and JLR is always jet-setting',
    buttonText: "See the world from JLR’s lens",
    link: "/jlr-travels",
    image: "/image/banner/_travel-poster2.jpg",
    showNav: true,
  },
  {
    title: "A palette <br/>for the piquant",
    description: 'Discover the pleasures of dishes divine and tastes refined',
    buttonText: "Checkout what’s on JLR’s Plate",
    link: "/jlr-foods",
    image: "/image/banner/_food-poster.jpg",
    showNav: true,
  },
  // {
  //   title: "A trailblazer with <br/>transformative ideas",
  //   description: '',
  //   buttonText: "Contact",
  //   link: "",
  //   image: "/image/banner/_contact-poster3.jpg",
  //   showNav: true,
  // },
];

const LandingCarousel = ({ slide }) => {
  // ** Refs
  const carouselLink = useRef(null);
  const location = useLocation()
  const navigate = useNavigate()

  const [activeIndex, setActiveIndex] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [carouselActive, setCarouselActive] = useState(false);

  let currIndex = activeIndex ? activeIndex : 0;

  const prev = () => {
    currIndex--;

    var carouselItems = document.querySelectorAll(".carousel .__carousel-item");
    if (currIndex < 0) {
      console.log("enter");
      currIndex = carouselItems.length - 1;
    }

    loadImage(currIndex);
  };
  const next = () => {
    currIndex++;

    var carouselItems = document.querySelectorAll(".carousel .__carousel-item");
    if (currIndex > carouselItems.length - 1) {
      currIndex = 0;
    }

    if (currIndex < 0) {
      currIndex = carouselItems.length - 1;
    }

    loadImage(currIndex);
  };

  const loadImage = (x) => {
    const image = new Image();
    image.onload = function () { };
    image.src = "";

    switch (x) {
      case 0:
        navigate('/about')
        break;
      case 1:
        navigate('/spaces')
        break;
      case 2:
        navigate('/travel')
        break;
      case 3:
        navigate('/food')
        break;
      // case 4:
      //   navigate('/contact')
      //   break;
    }

    setActiveIndex(x);
    setShowContent(false);

    setTimeout(() => { }, 0);
  };

  const handleBlur = (e) => {
    setCarouselActive(false)
  };

  const handleFocus = (e) => {
    const currentTarget = e.currentTarget;
    if (currentTarget === document.activeElement) {
      setCarouselActive(true)
    }
  }

  const getActiveIndex = (slug) => {
    switch (slug) {
      case 'about':
        return 0;
        break;
      case 'spaces':
        return 1;
        break;
      case 'travel':
        return 2;
        break;
      case 'food':
        return 3;
        break;
      // case 'contact':
      //   return 4;
      //   break;
      default: return 0
    }
  }

  const toggleAside = () => {
    const aside = document.getElementById('aside');
    aside.classList.toggle('active');
  }

  useEffect(() => {
    const page = location.pathname
    const slug = page.replace('/', '')

    let active = getActiveIndex(slug)

    setActiveIndex(active)
    setShowContent(false)
  }, [location])

  useEffect(() => {
    // console.log(activeIndex);
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, [activeIndex]);

  useEffect(() => {
    const close = (e) => {
      if (carouselActive) {
        if (e.key === "ArrowRight") {
          next()
        } else if (e.key === "ArrowLeft") {
          prev()
        }
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [carouselActive])

  return (
    <div id="about" className="relative h-full">
      <div className="carousel">
        <div className="__carousel-inner">
          {items.map((e, i) => {
            return (
              <div
                className={`__carousel-item ${activeIndex === i ? "active" : ""
                  }`}
                key={i}
              >

                <Fade>
                  <div className="group relative">
                    {/* <div className=""> */}
                    <div className="md:h-[calc(100vh-80px)] h-[calc(100vh-88px)]">
                      {Array.isArray(e.image) && (
                        <Fade
                          cascade={true}
                          triggerOnce={true}
                          direction="up"
                          duration={450}
                          className="custom-image-animation"
                        >
                          {e.image.map((image, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  index > 0
                                    ? `absolute top-0 w-full h-full`
                                    : ""
                                }
                              >
                                <img src={image} alt="" />
                              </div>
                            );
                          })}
                        </Fade>
                      )}
                      {/* {!Array.isArray(e.image) && <img className="object-cover object-bottom w-full h-[calc(100vh-72px)]" src={e.image} alt="" />} */}
                      {!Array.isArray(e.image) && <img className="object-cover object-bottom w-full h-full" src={e.image} alt="" />}
                    </div>

                    <div
                      className={`__carousel-content flex absolute top-0 left-0 w-full h-full items-center justify-center flex-col show`}
                    >
                      {/* <div className="md:w-10/12 w-4/5 md:mb-16 mb-4 overflow-hidden"> */}
                      <div className={`md:w-11/12 w-4/5 overflow-hidden ${activeIndex === 2 ? 'md:mt-16':'md:mt-0 md:mb-16 mb-4'} `}>
                        <div className="md:px-5">
                          <h2 className="fade-left title text-white md:text-7xl text-3xl leading-[1.15!important] font-black mb-8">
                            {ReactHtmlParser(e.title)}
                          </h2>

                          <div className="text-white">{e.description}&nbsp;</div>
                          {
                            e.buttonText === 'Contact' && (
                              <div className="mt-2">
                                <button onClick={toggleAside} className={`text-white underline font-semibold`} to={e.link}>{e.buttonText}</button>
                              </div>
                            )
                          }
                          {
                            e.buttonText !== 'Contact' && (
                              <div className="mt-2">
                                {/* <Link className={`text-white underline font-semibold`} to={e.link}>{e.buttonText}</Link> */}
                                <a className={`text-white underline font-semibold`} href={e.link}>{e.buttonText}</a>
                              </div>
                            )
                          }
                        </div>


                        <div className={`__carousel-nav md:px-5`} style={{ display: !e.showNav ? 'none' : 'flex' }}>
                          <div className="__prev" onClick={prev}>
                            <span></span>
                          </div>
                          <div className="__next" onClick={next}>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>

              </div>
            );
          })}
        </div>

        {/* <ol className="__carousel-indicator">
          <li data-slide-to="1"></li>
          <li data-slide-to="2"></li>
          <li data-slide-to="3"></li>
        </ol> */}
      </div>
    </div>
  );
};
export default LandingCarousel;
