import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { spaces } from 'src/common/spaces';
import { Carousel } from 'react-responsive-carousel';

const Space = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.25)
    }
  }
`;
const Image = styled.img`
  width: 100%;
  margin-inline: auto;
  display: block;
  object-fit: cover;
  transition: all 500ms ease-in-out;
`;
const Content = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: end;
  padding: 1.35em;
`;

const CarouselNavigation = styled.button`
  position: absolute;
  z-index: 2;
  bottom: -10%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: center;
  border: 1px solid #aaa;
  border-radius: 50%;
  transition: all 0.25s;
  &:hover {
    background-color: #28b0a6;
  }
`;

function Spaces() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [activeIndex, setActiveIndex] = useState(0)

  const handleResize = () => {
    // Your logic for handling resize
    console.log('Window resized!');
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    const carouselPrev = document.querySelector('.control-prev')
    const carouselNext = document.querySelector('.control-next')
    const carouselIndicatorNav = document.querySelector('.carousel-indicator-nav')

    if (carouselPrev && carouselNext) {
      carouselIndicatorNav.appendChild(carouselPrev);
      carouselIndicatorNav.appendChild(carouselNext);
    }

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selected = []
  const handleSlideChange = (index) => {
    selected.push(index)

    let i = 0;

    if (index === 1) {
      i = index + 1
      setActiveIndex(i)
    }

    if (index === spaces.length - 1) {
      i = 0
      setActiveIndex(i)
    }
  }

  return (
    <div id="spaces" className="site-page max-w-[1920px] block mx-auto px-5 flex">
      <div className="md:mt-[72px] mt-[100px] w-full md:flex items-center">
        {/* <div className="md:min-h-[calc(100vh-152px)] mt-[72px] w-full flex items-center"> */}
        {/* <div className="w-full flex items-center border"> */}
        {/* <div className="flex md:flex-col flex-col-reverse"> */}
        <div className="md:w-[992px] mx-auto flex flex-col">
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            stopOnHover={true}
            showIndicators={false}
            autoPlay={false}
            infiniteLoop={false}
            interval={2000}
            emulateTouch={true}
            centerMode={true}
            centerSlidePercentage={windowSize.width < 768 ? 100 : 33.33}
            swipeable={windowSize.width < 768 ? true : false}
            selectedItem={activeIndex}
            // animationHandler="fade" // somehow not working even swipeable is activated
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              <CarouselNavigation type="button" className='control-prev' onClick={() => { onClickHandler(); }} title={label} style={{ right: 45, backgroundColor: hasPrev ? '#28b0a6' : 'transparent', backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.46954 14.4866L1.88721 7.90431L8.46954 1.32275' stroke='${hasPrev ? 'white' : 'gray'}' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")` }} />
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              <CarouselNavigation type="button" className='control-next' onClick={() => { onClickHandler(); }} title={label} style={{ right: 5, backgroundColor: hasNext ? '#28b0a6' : 'transparent', backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.88867 14.9417L8.471 8.36016L1.88867 1.77783' stroke='${hasNext ? 'white' : 'gray'}' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")` }} />
            }
            onChange={windowSize.width >= 768 ? handleSlideChange : function () { }}
          >
            {
              // spaces.slice(0).reverse().map((item, i) => (
              spaces.map((item, i) => (
                <div className="slide-inner mx-2" key={i}>
                  <Space>
                    <Link to={item.slug}>
                      <Image src={item.img} alt="" />
                      <Content>
                        <div className="mb-5 text-left">
                          <div className="text-title text-md text-white ">{item.name}</div>
                          <div className="text-subtitle text-sm text-white">Project Type: {item.type}</div>
                        </div>
                      </Content>
                    </Link>
                  </Space>
                </div>
              ))
            }
          </Carousel>

          <div className="carousel-indicator md:mb-0 mb-5 md:mt-2 mt-5 flex justify-between" style={{ marginLeft: 0, width: '100%' }}>
            <div className="text-2xl font-bold">JLR's Spaces</div>
            <div className="carousel-indicator-nav"></div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Spaces;

