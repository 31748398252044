import Modal from 'react-modal';
import { Bounce } from 'react-reveal';
import { AiFillCloseCircle } from "react-icons/ai";

const customStyles = {
  content: {
   // top: '50%',
   // left: '50%',
   // transform: 'translate(-50%, -50%)',
   // minWidth: '90vw',
   // minHeight: '90vh',
   // overflow: 'hidden',
   // background: 'url(img/dialog/background.jpg) center/cover no-repeat',
   // borderRadius: '1em'
   border: '0'
  },
  overlay: {
   backgroundColor: 'rgba(0,0,0,0.5)',
   zIndex: 1050,
  }
}
Modal.setAppElement('#root');

export default function NotifModal({show, dismiss}) {
  
  return (
    <Modal 
      isOpen={show}
      style={customStyles}
      onRequestClose={dismiss}
      // shouldCloseOnOverlayClick={true}
      className="flex justify-center items-center w-full h-full border-none outline-none"
    >
      <div className="sm:w-[325px] w-[90%] block mx-auto relative md:rounded-[1em]">
        <Bounce>
          <div className="bg-white md:p-7 p-3">
            <div className="absolute right-2 top-2">
              <button className="text-3xl z-20" onClick={dismiss}>
                <AiFillCloseCircle />
              </button>
            </div>
            <div className="block">
              <div className="text-xl my-4 font-bold font-heading">Thanks for reaching out!</div>
              <p className="text-sm">We've received your inquiry, and we’re eager to assist. Our team is on it, and we’ll get back to you promptly!</p>
            </div>
          </div>
        </Bounce>
      </div>
    </Modal>
  );
}