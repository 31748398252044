import Home from 'src/pages/Home';
export const PrimaryMenu = [
    {
        label: 'About',
        path: '/jovy-rabelas'
    },
    {
        label: 'Spaces',
        path: '/spaces'
    },
    {
        label: 'Travel',
        path: '/travel'
    },
    {
        label: 'Food',
        path: '/food'
    },
    {
        label: 'Fashion',
        path: 'https://shoppersalley.ph/'
    },
]

